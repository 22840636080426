import { FC, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { PopupFooterData } from "../../App";

import PopupFooter from "./PopupFooter";

import classes from "./Hamburger.module.css";

const animationTiming = {
  enter: 500,
  exit: 350,
};

interface Props {
  footer: PopupFooterData;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
}

const Hamburger: FC<Props> = ({
  footer,
  selectedCategories,
  setSelectedCategories,
}) => {
  const [show, setShow] = useState(false);

  const viewAllClickHandler = () => {
    if (selectedCategories.length < 9) {
      setSelectedCategories([
        "news",
        "partners",
        "characters",
        "informers",
        "social",
        "coins",
        "series",
        "nft",
        "collections",
      ]);
    }
  };

  const buttonClickHandler = (category: string) => {
    if (selectedCategories.length === 9) {
      // Remove
      setSelectedCategories((prevState) => {
        const newState = [category];
        return newState;
      });
    } else {
      setSelectedCategories((prevState) => {
        if (prevState.includes(category)) {
          if (prevState && prevState.length > 1) {
            const newState = prevState.filter((item) => item !== category);
            return newState;
          }
          return prevState;
        } else {
          const newState = [...prevState, category];
          return newState;
        }
      });
    }
  };
  return (
    <>
      <button
        className={`${classes.hamburger} ${show ? classes.open : ""}`}
        onClick={() => setShow((prevState) => !prevState)}
      >
        <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            className={classes.noStroke}
            d="M60 29.9976C60 46.5661 46.5685 59.9976 30 59.9976C13.4315 59.9976 0 46.5661 0 29.9976C0 13.429 13.4315 -0.00244141 30 -0.00244141C46.5685 -0.00244141 60 13.429 60 29.9976Z"
            fill="white"
          />
          <circle
            cx="30"
            cy="30.0005"
            r="26"
            fill="white"
            stroke="#0F1011"
            strokeWidth="2"
          />
          <path
            className={classes.line1}
            d="M19.5 27H42"
            stroke="#0F1011"
            strokeWidth="2"
            strokeLinecap="square"
          />
          <path
            className={classes.line2}
            d="M19.5 33H42"
            stroke="#0F1011"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </svg>
      </button>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.OverlayOpen,
          exit: "",
          exitActive: classes.OverlayClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.overlay} onClick={() => setShow(false)}></div>
      </CSSTransition>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.ModalOpen,
          exit: "",
          exitActive: classes.ModalClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.popup}>
          <div className={`${classes.popupContainer}`}>
            <div className={classes.popupContent}>
              <h2>Menu</h2>
              <nav>
                <button
                  className={`${classes.all} ${
                    selectedCategories.length === 9 ? classes.selected : ""
                  }`}
                  onClick={viewAllClickHandler}
                >
                  {footer.viewAllLabel || "View all"}
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("news")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("news");
                  }}
                >
                  #news
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("partners")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("partners");
                  }}
                >
                  #partners
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("characters")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("characters");
                  }}
                >
                  #characters
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("informers")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("informers");
                  }}
                >
                  #informers
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("social")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("social");
                  }}
                >
                  #social
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("coins")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("coins");
                  }}
                >
                  #coins
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("series")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("series");
                  }}
                >
                  #series
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("nft")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("nft");
                  }}
                >
                  #nft
                </button>
                <button
                  className={
                    selectedCategories.length < 9 &&
                    selectedCategories.includes("collections")
                      ? classes.selected
                      : ""
                  }
                  onClick={() => {
                    buttonClickHandler("collections");
                  }}
                >
                  #collections
                </button>
              </nav>
            </div>
            <PopupFooter {...footer} />
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Hamburger;
