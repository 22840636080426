import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import parse from "html-react-parser";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import classes from "./Popup.module.css";
import { PopupDataAction, PopupFooterData } from "../../App";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PopupFooter from "./PopupFooter";

const animationTiming = {
  enter: 500,
  exit: 350,
};

const typesToGraphQL: {
  logo: string;
  news: string;
  partners: string;
  characters: string;
  informers: string;
  social: string;
  coins: string;
  series: string;
  nft: string;
  collections: string;
} = {
  logo: "",
  news: "newsBy",
  partners: "partnerBy",
  characters: "characterBy",
  informers: "informerBy",
  social: "socialBy",
  coins: "coinBy",
  series: "serieBy",
  nft: "nftBy",
  collections: "collectionBy",
};

interface Props {
  show?: boolean;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
  setPopupData: Dispatch<SetStateAction<PopupDataAction>>;
  type:
    | "logo"
    | "news"
    | "partners"
    | "characters"
    | "informers"
    | "social"
    | "coins"
    | "series"
    | "nft"
    | "collections";
  slug: string;
  title: string;
  date?: string;
  leadVisualImage?: {
    sourceUrl: string;
    altText?: string;
    mediaDetails: {
      width: number;
      height: number;
    };
  };
  footer: PopupFooterData;
}

const Popup: FC<Props> = ({
  show = false,
  setShowPopup,
  setPopupData,
  type,
  slug,
  title,
  date,
  leadVisualImage,
  footer,
}) => {
  const [popupLoading, setPopupLoading] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [canShare, setCanShare] = useState<any | undefined>(undefined);
  const [copied, setCopied] = useState(false);
  const REACT_APP_URL = "https://tmm.world";
  const shareUrl = `${REACT_APP_URL}/#${slug}`;
  const closePopup = () => {
    setPopupData({
      type: "logo",
      slug: "",
      title: "",
      date: "",
      leadVisualImage: undefined,
    });
    setShowPopup(false);
    window.location.hash = "";
  };

  var parts = date ? date.split("/") : [];
  const dateObj = date
    ? new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]))
    : new Date();
  const month = date
    ? dateObj.toLocaleString("default", { month: "long" })
    : "";
  const day = date ? dateObj.getDate() : "";
  const year = date ? dateObj.getFullYear() : "";
  const fullDate = date ? `${month} ${day}, ${year}` : "";

  const copyClickHandler = () => {
    navigator.clipboard.writeText(shareUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const shareContextHandler = () => {
    const shareData = {
      title: title,
      url: shareUrl,
    };
    navigator.share(shareData);
  };

  useEffect(() => {
    const REACT_APP_API_URL = "https://new.tmm.world";
    const grpahQLType = typesToGraphQL[type];
    const graphQL = `{
        ${grpahQLType}(slug: "${slug}") {
          content
        }
      }`;
    const fetchPopupData = async () => {
      try {
        const qraphQLURL = `${REACT_APP_API_URL}/index.php?graphql&query=${encodeURIComponent(
          graphQL
        )}`;
        const graphql = await fetch(qraphQLURL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await graphql.json();
        setPopupContent(data.data[grpahQLType].content);
        setPopupLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (type && slug) {
      setPopupLoading(true);
      fetchPopupData();
    }
  }, [type, slug]);

  useEffect(() => {
    setCanShare(navigator["share"]);
  }, []);
  return (
    <>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.OverlayOpen,
          exit: "",
          exitActive: classes.OverlayClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.overlay} onClick={closePopup}></div>
      </CSSTransition>
      <CSSTransition
        in={show}
        timeout={animationTiming}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: "",
          enterActive: classes.ModalOpen,
          exit: "",
          exitActive: classes.ModalClosed,
          appear: "",
          appearActive: "",
        }}
      >
        <div className={classes.popup}>
          <button className={classes.close} onClick={closePopup}>
            <svg
              viewBox="0 0 60 61"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="30" cy="31" r="30" />
              <circle cx="30" cy="31" r="26" stroke="#0F1011" strokeWidth="2" />
              <path
                d="M22 39L37.5563 23.4437"
                stroke="#0F1011"
                strokeWidth="2"
                strokeLinecap="square"
              />
              <path
                d="M22 23L37.5563 38.5563"
                stroke="#0F1011"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>
          </button>
          {popupLoading && (
            <div className={classes.popupLoading}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200px"
                height="200px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <g transform="translate(28,28)">
                  <rect x="-16" y="-16" width="32" height="32" fill="#d8323a">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      repeatCount="indefinite"
                      dur="1s"
                      keyTimes="0;1"
                      values="1.35;1"
                      begin="-0.3s"
                    ></animateTransform>
                  </rect>
                </g>
                <g transform="translate(72,28)">
                  <rect x="-16" y="-16" width="32" height="32" fill="#298ed7">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      repeatCount="indefinite"
                      dur="1s"
                      keyTimes="0;1"
                      values="1.35;1"
                      begin="-0.2s"
                    ></animateTransform>
                  </rect>
                </g>
                <g transform="translate(28,72)">
                  <rect x="-16" y="-16" width="32" height="32" fill="#27a845">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      repeatCount="indefinite"
                      dur="1s"
                      keyTimes="0;1"
                      values="1.35;1"
                      begin="0s"
                    ></animateTransform>
                  </rect>
                </g>
                <g transform="translate(72,72)">
                  <rect x="-16" y="-16" width="32" height="32" fill="#eba934">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      repeatCount="indefinite"
                      dur="1s"
                      keyTimes="0;1"
                      values="1.35;1"
                      begin="-0.1s"
                    ></animateTransform>
                  </rect>
                </g>
              </svg>
            </div>
          )}
          {!popupLoading && (
            <div className={`${classes.popupContainer}`}>
              {leadVisualImage && (
                <div className={classes.visual}>
                  <LazyLoadImage
                    alt={leadVisualImage.altText || title}
                    height={leadVisualImage.mediaDetails.height}
                    src={leadVisualImage.sourceUrl}
                    width={leadVisualImage.mediaDetails.width}
                  />
                </div>
              )}
              <div className={classes.popupContent}>
                {date && <div className={classes.date}>{fullDate}</div>}
                {title && <h1>{title}</h1>}
                <div className={classes.wysiwyg}>{parse(popupContent)}</div>
                <div className={classes.share}>
                  <div className={classes.shareDesktop}>
                    <p>Share:</p>
                    <nav>
                      <FacebookShareButton url={shareUrl}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path d="M23.9991 12.073C23.9991 5.40405 18.6261 -0.00195312 11.9991 -0.00195312C5.36909 -0.000453125 -0.00390625 5.40405 -0.00390625 12.0745C-0.00390625 18.1 4.38509 23.095 10.1211 24.001V15.5635H7.07609V12.0745H10.1241V9.41205C10.1241 6.38655 11.9166 4.71555 14.6571 4.71555C15.9711 4.71555 17.3436 4.95105 17.3436 4.95105V7.92105H15.8301C14.3406 7.92105 13.8756 8.85255 13.8756 9.80805V12.073H17.2026L16.6716 15.562H13.8741V23.9995C19.6101 23.0935 23.9991 18.0985 23.9991 12.073Z" />
                        </svg>
                      </FacebookShareButton>
                      <TwitterShareButton url={shareUrl}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M0.553907 1L9.10725 12.8939L0.5 22.5642H2.4373L9.9731 14.0975L16.0616 22.5642H22.6538L13.6191 10.0014L21.6307 1H19.6934L12.7536 8.79741L7.14615 1H0.553907ZM3.40279 2.48393H6.43124L19.8046 21.0803H16.7761L3.40279 2.48393Z"
                            fill="white"
                          />
                        </svg>
                      </TwitterShareButton>
                      <button
                        className={copied ? classes.copied : ""}
                        onClick={copyClickHandler}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="24" height="24" rx="12" />

                          <path
                            d="M16 10.001V8.50098C16 8.10315 15.842 7.72162 15.5607 7.44032C15.2794 7.15901 14.8978 7.00098 14.5 7.00098H8.5C8.10218 7.00098 7.72064 7.15901 7.43934 7.44032C7.15804 7.72162 7 8.10315 7 8.50098V14.501C7 14.8988 7.15804 15.2803 7.43934 15.5616C7.72064 15.8429 8.10218 16.001 8.5 16.001H10"
                            stroke="#0F1011"
                            strokeWidth="1.31184"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            className={classes.noFill}
                            d="M17.502 9.99902H11.502C10.6735 9.99902 10.002 10.6706 10.002 11.499V17.499C10.002 18.3275 10.6735 18.999 11.502 18.999H17.502C18.3304 18.999 19.002 18.3275 19.002 17.499V11.499C19.002 10.6706 18.3304 9.99902 17.502 9.99902Z"
                            fill="#0F1011"
                          />
                        </svg>
                      </button>
                    </nav>
                  </div>
                  {canShare && (
                    <div className={classes.shareDevice}>
                      <button onClick={shareContextHandler}>Share</button>
                    </div>
                  )}
                </div>
              </div>
              <PopupFooter {...footer} />
            </div>
          )}
        </div>
      </CSSTransition>
    </>
  );
};

export default Popup;
