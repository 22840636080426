import { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import classes from "./PopupFooter.module.css";
import { PopupFooterData } from "../../App";

interface Props extends PopupFooterData {}

const PopupFooter: FC<Props> = ({
  copyright = "All rights reserved",
  email,
  socialNetworks,
  viewAllLabel = "View all",
  studioLogo,
}) => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className={classes.footer}>
      {studioLogo && (
        <div className={classes.logo}>
          <LazyLoadImage
            alt={studioLogo.altText || ""}
            height={studioLogo.mediaDetails.height}
            src={studioLogo.sourceUrl}
            width={studioLogo.mediaDetails.width}
          />
        </div>
      )}
      <div className={classes.bottom}>
        <div className={classes.copyright}>{`© ${year}. ${copyright}`}</div>
        <div className={classes.center}>
          {socialNetworks && socialNetworks.length > 0 && (
            <nav>
              {socialNetworks.map((item, index) => (
                <a
                  key={`social-${index}`}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LazyLoadImage
                    alt={item.logo.altText || item.title}
                    height={item.logo.mediaDetails.height}
                    src={item.logo.sourceUrl}
                    width={item.logo.mediaDetails.width}
                  />
                </a>
              ))}
            </nav>
          )}
          {email && <a href={`mailto:${email}`}>{email}</a>}
        </div>
        <div className={classes.developer}>
          Website by{" "}
          <a href="https://69pixels.com/" target="_blank" rel="noreferrer">
            69pixels.
          </a>
        </div>
      </div>
    </footer>
  );
};

export default PopupFooter;
