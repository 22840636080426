import { FC } from "react";
import classes from "./Loading.module.css";

interface Props {
  active?: boolean;
}

const Loading: FC<Props> = ({ active = false }) => {
  return (
    <div className={`${classes.loading} ${active ? classes.active : ""}`}></div>
  );
};

export default Loading;
